<template>
  <!-- 数据系统首页 -->
  <div class="paddbox">
    <div class="indexData">
      <el-card class="box-card">
        <marquee
          v-if="flag == 1&&type==6"
          direction="right"
          style="color:red;font-size:18px;"
          ><i class="el-icon-message-solid"></i
          >海外页面充值和消耗都是美元</marquee
        >
        <div class="indexdata-top">
          <div class="index-top-left">
            <!-- monthrange -->

            <div class="alcenter">
              <div v-if="radio1 == 0">
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcuts"
                  format="YYYY-MM-DD"
                  :default-value="new Date()"
                  value-format="YYYY-MM-DD"
                  @change="dateChange"
                >
                </el-date-picker>
              </div>
              <div v-if="radio1 == 1">
                <el-date-picker
                  v-model="date"
                  type="monthrange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcutss"
                  format="YYYY-MM"
                  :default-value="new Date()"
                  value-format="YYYY-MM"
                  @change="dateChange"
                >
                </el-date-picker>
              </div>
              <div style="margin-left:30px">
                <el-select
                  v-model="type"
                  @change="typehange"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div style="margin-left:30px">
                <el-radio-group
                  v-model="radio1"
                  size="medium"
                  @change="typehanges"
                >
                  <el-radio-button label="0">日统计</el-radio-button>
                  <el-radio-button label="1" v-if="flag == 1"
                    >月统计</el-radio-button
                  >
                </el-radio-group>
              </div>
            </div>
            <div class="datashou">
              <el-card class="box-card ">
                <div class="data-car">
                  <p v-if="type != 5">
                    至今回本：<span v-if="totalDatas"
                      >{{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? (
                              (totalDatas.amount / totalDatas.cost) *
                              100
                            ).toFixed(2)
                          : 0
                      }}%</span
                    >
                  </p>
                  <p v-if="type == 5">
                    至今回本：<span v-if="totalDatas"
                      >{{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? totalDatas.amount - totalDatas.cost > 0
                            ? (
                                (((totalDatas.amount - totalDatas.cost) / 2 +
                                  totalDatas.cost) /
                                  totalDatas.cost) *
                                100
                              ).toFixed(2)
                            : (
                                (totalDatas.amount / totalDatas.cost) *
                                100
                              ).toFixed(2)
                          : 0
                      }}%</span
                    >
                  </p>

                  <!-- <p v-if="radio1 == 0">
                    总付费成本：<span>{{ getPayCostDate.toFixed(2) }}</span>
                  </p> -->
                </div>
                <div class="data-carfor flex">
                  <el-tag type="checked" effect="plain">
                    消耗：
                    <p v-if="totalDatas">{{ totalDatas.cost }}</p>
                  </el-tag>
                  <el-tag type="success" effect="plain">
                     充值：
                     <!-- 除了海外页面的充值 -->
                    <p v-if="totalDatas && type!=6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? totalDatas.amount
                          : 0
                      }}
                    </p>
                    <!-- 海外页面的充值，除了管理员和董翔和财务其他都只能看60天 -->
                    <p v-if="totalDatas&& type==6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ?( (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?totalDatas.amount:totalDatas.amount60)
                          : 0
                      }}
                    </p>
                  </el-tag>
                  <el-tag type="danger" effect="plain">
                    利润：
                    <!-- 除开海外和奇树 的其他页面 -->
                    <p v-if="totalDatas && type != 5&& type != 6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? (totalDatas.amount - totalDatas.cost).toFixed(2)
                          : 0
                      }}
                    </p>
                    <!-- 奇树 -->
                    <p v-if="totalDatas && type == 5">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? totalDatas.amount - totalDatas.cost > 0
                            ? (
                                (totalDatas.amount - totalDatas.cost) /
                                2
                              ).toFixed(2)
                            : (totalDatas.amount - totalDatas.cost).toFixed(2)
                          : 0
                      }}
                    </p>
                    <!-- 海外 -->
                    <p v-if="totalDatas && type == 6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' &&
                          (shujuyuanName == '观察员' ||
                            shujuyuanName == 'xieqianhui2'))
                          ? ( (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(totalDatas.amount - totalDatas.cost).toFixed(2):(totalDatas.amount60 - totalDatas.cost).toFixed(2))
                          : 0
                      }}
                    </p>
                  </el-tag>

                  <div
                    style="display:inline;cursor: pointer;"
                    @click="initialDeailsFun"
                  >
                    <el-tag type="warning" effect="plain">
                      首充：

                      <p v-if="totalDatas">
                        {{
                          shujuyuan != 'shujuyuan' ||
                          (shujuyuan == 'shujuyuan' &&
                            (shujuyuanName == '观察员' ||
                              shujuyuanName == 'xieqianhui2'))
                            ? ((firstAmount / totalDatas.cost) * 100).toFixed(2)
                            : 0
                        }}%
                      </p>
                      <!-- <p v-if="amounts">{{ amounts }}%</p> -->
                    </el-tag>
                  </div>
                  <br />
                  <el-tag type="checked" effect="plain">
                    首冲人数：
                    <p v-if="totalDatas">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' && shujuyuanName == '观察员')
                          ? totalDatas.payNumber
                          : 0
                      }}
                    </p>
                  </el-tag>
                  <el-tag type="success" effect="plain">
                    充值次数：
                    <p v-if="totalDatas">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' && shujuyuanName == '观察员')
                          ? totalDatas.userNumber
                          : 0
                      }}
                    </p>
                  </el-tag>
                  <el-tag type="danger" effect="plain">
                    进粉：
                    <!-- 海外 -->
                    <p v-if="totalDatas&&type==6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' && shujuyuanName == '观察员')
                          // ? ((rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(totalDatas.amount / totalDatas.users).toFixed(2):(totalDatas.amount60 / totalDatas.users).toFixed(2))
                          ? ((rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?totalDatas.users:totalDatas.users)
                          : 0
                      }}
                    </p>
                    <!-- 非海外 -->
                    <p v-if="totalDatas&&type!=6">
                      {{
                        shujuyuan != 'shujuyuan' ||
                        (shujuyuan == 'shujuyuan' && shujuyuanName == '观察员')
                          ? totalDatas.users
                          : 0
                      }}
                    </p>
                  </el-tag>
                </div>
              </el-card>
            </div>
          </div>
          <!-- echarts -->
          <!-- <div class="index-top-right">
            <div class="map" id="barSimple">加载中</div>
          </div> -->
          <!-- <div>
        <div id="timeSharingCharts" style="width: 900px;height: 400px;"></div>
      </div> -->
        </div>
        <div class="indexdata-bot">
          <div class="indexdata-bot-top flex">
            <div @click="gatherDeailsDialogFun()">
              <el-button icon="el-icon-s-tools" circle></el-button>
            </div>
            <div class="bot-top-plus" @click="timeSharingFun()" v-if="radio1 == 0&&shujuyuanName!='测试'">
              <el-button icon="el-icon-time" circle></el-button>
            </div>
            <div class="flex ">
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="userName"
                  multiple
                  placeholder="投放人"
                >
                  <el-option
                    v-for="item in getRequestLists.userName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="platform"
                  multiple
                  placeholder="平台"
                >
                  <el-option
                    v-for="item in getRequestLists.platform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="wp"
                  multiple
                  placeholder="公众号"
                >
                  <el-option
                    v-for="item in getRequestLists.wp"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="platformNames"
                  multiple
                  placeholder="书城平台"
                >
                  <el-option
                    v-for="item in getRequestLists.platformName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:80px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="adPlatform"
                  multiple
                  placeholder="广告平台"
                >
                  <el-option
                    v-for="item in getRequestLists.adPlatform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:90px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="bookName"
                  multiple
                  placeholder="书名"
                >
                  <el-option
                    v-for="item in getRequestLists.bookName"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:90px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="partnerPlatform"
                  multiple
                  placeholder="渠道"
                >
                  <el-option
                    v-for="item in getRequestLists.partnerPlatform"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div
                class="indexdata-bot-select"
                :style="
                  type == 3 || type == 4 || type == 5|| type == 6|| type == 7 ? 'width:100px' : ''
                "
              >
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="typeOfAmountss"
                  multiple
                  placeholder="充值模版"
                >
                  <el-option
                    v-for="item in getRequestLists.typeOfAmount"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="indexdata-bot-select">
                <el-select clearable filterable v-model="recovery" multiple placeholder="回收率">
                  <el-option
                    v-for="item in recoverys"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <div
                class="indexdata-bot-select"
                style="width:80px"
                v-if="type == 3 || type == 4 || type == 5|| type == 6|| type == 7"
              >
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="videoTypes"
                  multiple
                  placeholder="小程序类别"
                >
                  <el-option
                    v-for="item in getRequestLists.videoType"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="indexdata-bot-select" style="width:90px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="accountId"
                  multiple
                  placeholder="账户ID"
                >
                  <el-option
                    v-for="item in getRequestLists.accountId"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="indexdata-bot-select" style="width:60px">
                <el-select v-model="isEnable" placeholder="是否首充">
                  <el-option
                    v-for="item in isEnableArr"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="byteVersions"
                  multiple
                  placeholder="字节版本"
                >
                  <el-option
                    v-for="item in getRequestLists.byteVersion"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div> -->
              <div class="indexdata-bot-select" style="width:100px">
                <el-cascader
                  v-model="groupName"
                  :options="getRequestLists.groupName"
                  :props="{ checkStrictly: true, value: 'id', label: 'label' }"
                  clearable
                  placeholder="部门"
                ></el-cascader>
              </div>
              <div class="indexdata-bot-select" style="width:100px">
                <el-select
                  collapse-tags
                  clearable
                  filterable
                  v-model="orderbyValue"
                  placeholder="降序字段"
                >
                  <el-option
                    v-for="item in orderby"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="searchbtn">
                <el-button
                  type="primary"
                  @click="alldataFun()"
                  >搜索</el-button
                >
              </div>
              <div class="searchbtn" v-if="radio1 == 0">
                <el-button type="success" @click="exportFun()">导出</el-button>
              </div>
            </div>

            <div class="bot-top-plus" @click="addDataFun" v-if="radio1 == 0">
              <el-button icon="el-icon-plus" circle></el-button>
            </div>
          </div>
          <el-table
            :data="tableData"
            height="780"
            style="width: 1600px"
            border
            sort-orders="['ascending', 'descending']"
            @cell-click="addDetailsFun"
          >
            <el-table-column
              sortable
              label="投放人"
              prop="userName"
              width="90"
            />
            <el-table-column
              sortable
              label="账户ID"
              prop="accountId2"
              width="90"
            />

            <el-table-column sortable label="平台" prop="platform" width="80" />
            <el-table-column sortable label="公众号" prop="wp" width="110" />
            <el-table-column
              sortable
              label="广告平台"
              prop="adPlatform"
              width="80"
            />
            <el-table-column
              sortable
              label="充值模版"
              prop="typeOfAmount"
              width="60"
            >
              <template #default="scope">
                {{
                  scope.row.typeOfAmount == 0
                    ? '其他'
                    : scope.row.typeOfAmount == 1
                    ? '小额'
                    : scope.row.typeOfAmount == 2
                    ? '中额'
                    : scope.row.typeOfAmount == 3
                    ? '大额'
                    : scope.row.typeOfAmount == 4
                    ? '客服消息'
                    : '图文消息'
                }}
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="书名"
              prop="bookName"
              width="130"
            />

            <el-table-column
              v-if="
                thisdate[0] == date[0] &&
                  thisdate[1] == date[0] &&
                  thisdate[0] == date[1]
              "
              sortable
              label="账户币余额"
              prop="accountCurrency"
              width="80"
            />
            <el-table-column sortable label="粉丝" prop="newFans" width="80" />
            <el-table-column sortable label="成本" prop="cost" width="110" />
            <el-table-column 
              sortable
              label="充值"
              prop="totalAmount"
              width="130"
            >
              <template #default="scope">
                <!-- <span>{{
                  (scope.row.totalAmount / scope.row.cost).toFixed(2) > 0.99 &&
                  flag != 1
                    ? '已回本'
                    : scope.row.totalAmount
                }}</span> -->
                <!-- 非海外 -->
                <span v-if="type!=6&&type!=4" >{{ scope.row.totalAmount }}</span>
                <!-- 海外 -->
                <span v-if="type==6" >{{ (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')? scope.row.totalAmount:scope.row.amount60 }}</span>
                <!-- iaa短剧 -->
                <span v-if="type==4" >{{ (scope.row.totalAmount+scope.row.iaaTotalAmount).toFixed(2)+'('+scope.row.iaaTotalAmount+')' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="广告激励"
              prop="name"
              width="100"
              v-if="type == 4"
            >
              <template #default="scope">
                <span
                  >
                  {{scope.row.totalAmount}}
                  </span
                >
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="首充"
              prop="name"
              width="80"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0"
                  >{{
                    ((scope.row.amount / scope.row.cost) * 100).toFixed(2)
                  }}%</span
                >
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="首充人数"
              prop="name"
              width="160"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0"
                  >{{
                    ((scope.row.count / scope.row.newFans) * 100).toFixed(2)
                  }}%({{ scope.row.count }}次/{{ scope.row.scrs }})人</span
                >
              </template>
            </el-table-column>
            <el-table-column
              sortable
              label="付费成本"
              prop="name"
              width="80"
              v-if="radio1 == 0"
            >
              <template #default="scope">
                <span v-if="radio1 == 0">{{
                  scope.row.scrs == 0
                    ? 0
                    : (scope.row.cost / scope.row.scrs).toFixed(2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable label="回收率" prop="name" width="90">
              <template #default="scope">
                <!-- <span>{{
                  (scope.row.totalAmount / scope.row.cost).toFixed(2) > 0.99 &&
                  flag != 1
                    ? '已回本'
                    : ((scope.row.totalAmount / scope.row.cost) * 100).toFixed(
                        2
                      ) + '%'
                }}</span> -->
                <!-- 非海外 -->
                <span v-if="type!=6">{{
                  ((scope.row.totalAmount / scope.row.cost) * 100).toFixed(2) +
                    '%'
                }}</span>
                 <!-- 海外 -->
                      <span v-if="type==6">{{
                  (rId==16||shujuyuanName == 'xieqianhui2'|| shujuyuanName == '董翔2')?(((scope.row.totalAmount / scope.row.cost) * 100).toFixed(2))+'%':(((scope.row.amount60 / scope.row.cost) * 100).toFixed(2)) +
                    '%'
                }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column sortable label="单粉产出" prop="name" width="80">
              <template #default="scope">
                <span>{{ (scope.row.totalAmount / scope.row.newFans).toFixed(2) }}</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="分组" prop="deptName" width="80" /> -->
            <el-table-column label="渠道" prop="partnerPlatform" width="80" />

            <el-table-column sortable label="投放日" prop="day" />
            <el-table-column label="操作" width="160" v-if="type == 4||type == 7">
              <template #default="scope">
                <el-button type="primary" @click="suppDataFun(scope)"
                  >补数据</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination" style="margin-top:20px">
          <pagination
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :total="total"
            :pagesize="pagesize"
            :currentPage="currentPage"
            :pagesizes="pagesizes"
          ></pagination>
        </div>
      </el-card>
    </div>
    <!-- 分时弹窗 -->
    <el-dialog title="分时" v-model="timeSharing" width="50%" @open="getTimeSharingOrderFun()">  
      <div class="bookNameTitle flex">
        {{ bookName[0] }}
      </div>
      <div>
        <div id="timeSharingCharts" style="width: 900px;height: 400px;" ref="b"></div>
      </div>
    </el-dialog>
    <!-- 首充弹窗 -->
    <!-- totalDatas.cost总消耗 -->
    <el-dialog title="首充详情" v-model="initialDeails" width="90%">
      <div class="initialDeails flex">
        <div v-for="(i, index) in TotalRecoverys.orderCreateTime">
          <!-- v-if="
            当初这样写是王总要求100%回收看不到
              TotalRecoverys.sumAmount[index] / totalDatas.cost < 1 || flag == 1 
            " -->
          <div
            class="initialDeailsitem"
            v-if="
              TotalRecoverys.sumAmount[index] / totalDatas.cost < 1 ||
                flag == 1 ||
                Math.abs(
                  new Date(TotalRecoverys.orderCreateTime[index]) -
                    new Date(TotalRecoverys.orderCreateTime[0])
                ) /
                  1000 /
                  60 /
                  60 /
                  24 <
                  38
            "
          >
            <p>
              第{{ TotalRecoverys.day[index] }}{{ radio1 == 0 ? '天' : '个月' }}
            </p>
            <p>{{ TotalRecoverys.orderCreateTime[index] }}</p>
            <!-- <p>
            {{ firstDiy[index].toFixed(2) }}
            ({{ ((firstDiy[index] / totalDatas.cost) * 100).toFixed(2) }}%)
          </p> -->
            <p>
              {{ TotalRecoverys.sumAmount[index].toFixed(2) }}
              ({{
                (
                  (TotalRecoverys.sumAmount[index] / totalDatas.cost) *
                  100
                ).toFixed(2)
              }}%)
            </p>
            <p>+{{ TotalRecoverys.amount[index] }}</p>
            <p style=" color: #f56c6c;">
              +{{
                (
                  (TotalRecoverys.amount[index] / totalDatas.cost) *
                  100
                ).toFixed(2)
              }}%
            </p>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 个人回收弹窗 -->
    <!-- totalDatas.cost总消耗 -->
    <el-dialog title="回收详情" v-model="WpRecoveryByDayDeails" width="90%">
      <div class="initialDeails flex">
        <div v-for="(i, index) in WpRecoveryByDayData">
          <!-- v-if="i.sumAmount / rowcost < 1 || flag == 1" -->
          <div class="initialDeailsitem">
            <p style=" color: #f56c6c;">
              第{{ i.day }}{{ radio1 == 0 ? '天' : '个月' }}
            </p>
            <p>{{ i.orderPayTime }}</p>
            <p>{{ i.count }}次/ {{ i.scrs }}人</p>
            <p>
              {{ i.sumAmount.toFixed(2) }}
              ({{ ((i.sumAmount / rowcost) * 100).toFixed(2) }}%)
            </p>
            <p>+{{ i.amount }}</p>
            <p style=" color: #f56c6c;">
              +{{ ((i.amount / rowcost) * 100).toFixed(2) }}%
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 新增数据弹窗 -->
    <el-dialog title="数据" v-model="addDataShou" width="30%">
      <!-- <div class="addDataShouSelect alcenter" v-if="type == 3">
        <div class="addDataShouSelect-name">投放类型：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="optype"
          placeholder="投放类型："
        >
          <el-option v-for="item in optypes" :key="item.id" :label="item.value" :value="item.id">
          </el-option>
        </el-select>
      </div> -->
      <div
        class="addDataShouSelect alcenter"
        v-if="type == 3 || type == 4 || type == 5|| type == 6|| type == 7"
      >
        <div class="addDataShouSelect-name">小程序类别：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="videoType"
          placeholder="小程序类别"
        >
          <el-option
            v-for="item in options.videoType"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="addDataShouSelect alcenter"
        v-if="
          (type == 3 || type == 4 || type == 5|| type == 6|| type == 7) &&
            (videoType == 2 || videoType == 4|| videoType == 3)
        "
      >
        <div class="addDataShouSelect-name">推广id:</div>
        <el-input
          style="width:100%"
          :disabled="addDeleteDialog"
          clearable
          v-model="wps"
          placeholder="推广id"
        >
        </el-input>
      </div>
      <div class="addDataShouSelect alcenter" v-if="videoType == 1">
        <div class="addDataShouSelect-name">推广号：</div>
        <el-radio-group v-model="spread" style="width:100%">
          <el-radio :label="1" size="large">是</el-radio>
          <el-radio :label="2" size="large">否</el-radio>
        </el-radio-group>
      </div>

      <div
        class="addDataShouSelect alcenter"
        v-if="
          ((type == 3 || type == 4 || type == 5|| type == 6|| type == 7) &&
            (videoType == 2 || videoType == 4|| videoType == 3)) ||
            (spread == 1 && videoType == 1)
        "
      >
        <div class="addDataShouSelect-name">日期：</div>
        <el-date-picker
          style="width:100%"
          v-model="day"
          type="date"
          :disabled="addDeleteDialog"
          placeholder="选择日期"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </div>

      <!-- <div
        class="addDataShouSelect alcenter"
        v-if="videoType == 1 || videoType == 3 || type != 3"
      > -->
      <div
        class="addDataShouSelect alcenter"
        v-if="
          videoType == 1 ||
            (type != 3 && type != 4 && type != 5&& type != 6&& type != 7)
        "
      >
        <div class="addDataShouSelect-name">
          {{ spread == 1 && videoType == 1 ? '推广id：' : '公众号：' }}
        </div>
        <el-input
          v-if="spread == 1 && videoType == 1"
          style="width:100%"
          clearable
          v-model="wps"
          placeholder="请输入推广id"
        >
        </el-input>
        <el-select
          style="width:100%"
          v-else
          clearable
          filterable
          v-model="addwp"
          @change="getContextFun()"
          placeholder="搜索公众号"
        >
          <el-option
            v-for="item in options.wp"
            :key="item.value"
            :label="item.id"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">投放人：</div>
        <el-select
          style="width:100%"
          filterable
          clearable
          v-model="adduserName"
          placeholder="投放人"
        >
          <el-option
            v-for="item in options.userName"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">广告平台：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="addadPlatform"
          placeholder="广告平台"
        >
          <el-option
            v-for="item in options.adPlatform"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">字节版本：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="byteVersion"
          placeholder="字节版本"
        >
          <el-option
            v-for="item in options.byteVersion"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">渠道：</div>
        <el-select
          style="width:100%"
          clearable
          filterable
          v-model="addpartnerPlatform"
          placeholder="渠道"
        >
          <el-option
            v-for="item in options.partnerPlatform"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div
        class="addDataShouSelect alcenter"
        v-if="type == 3 || type == 4 || type == 5|| type == 6|| type == 7"
      >
        <div class="addDataShouSelect-name">充值模版：</div>
        <el-select
          style="width:100%"
          filterable
          v-model="typeOfAmount"
          placeholder="充值模版"
        >
          <el-option
            v-for="item in typeOfAmounts"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">书城平台：</div>
        <el-select
          style="width:100%"
          filterable
          v-model="platformName"
          placeholder="书城平台"
        >
          <el-option
            v-for="item in options.platformName"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">书名：</div>
        <el-input
          :disabled="noChangeOfBookName"
          style="width:100%"
          clearable
          v-model="addbookName"
          placeholder="请输入书名"
        >
        </el-input>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">消耗：</div>
        <el-input
          style="width:100%"
          type="number"
          clearable
          v-model="addcost"
          placeholder="请输入消耗"
        >
        </el-input>
      </div>

      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">粉丝数：</div>
        <el-input
          style="width:100%"
          type="number"
          clearable
          v-model="addnewUsers"
          placeholder="请输入粉丝数"
        >
        </el-input>
      </div>
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">投放平台:</div>
        <el-radio-group v-model="insetPlatformType">
          <el-radio :label="'巨量'">巨量</el-radio>
          <el-radio :label="'腾讯广点通'">腾讯广点通</el-radio>
          <el-radio :label="'百度'">百度</el-radio>
          <el-radio :label="'快手'">快手</el-radio>
          <el-radio :label="'海外'">海外</el-radio>
          <el-radio :label="'其他'">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="addDataShouSelect alcenter">
        <div class="addDataShouSelect-name">账户id：</div>
        <el-input
          style="width:100%"
          clearable
          v-model="addaccountId"
          placeholder="请输入账户id"
        >
        </el-input>
      </div>

      <div class="depart-btn flex" v-if="radio1 == 0">
        <div>
          <el-button type="success" size="mini" @click="addDataOk"
            >确定</el-button
          >
        </div>
        <div v-if="addDeleteDialog">
          <el-button type="danger" size="mini" @click="addDelete"
            >删除</el-button
          >
        </div>
        <div>
          <el-button size="mini" @click="addDataErr">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 个人数据弹窗 -->
    <el-dialog
      title="集成"
      v-model="gatherDeailsDialog"
      width="95%"
      top="0.2vh"
    >
      <integration
        @excelOutFunon="excelOutFunon"
        :IntegrationData="IntegrationData"
        :haiwaiData="haiwaiData"
      ></integration>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeUnmount } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import {
  getRequestList,
  totalData,
  TotalRecovery,
  getPayCost,
  tableData,
  getFillParameter,
  getContext,
  deleteContext,
  updateContext,
  tableDataByMonth,
  TotalRecoveryByMonth,
  totalDataByMonth,
  monthgetRequestList,
  getIntegrationData,
  WpRecoveryByDay,
  homePageDataExcel,
  suppData,
  getTimeSharingOrder,
} from '@/request/new/nueDataSystem'
import pagination from '@/components/pagination.vue'
import integration from '@/components/integration.vue'
import * as echarts from 'echarts'
export default {
  name: 'indexData',
  components: { pagination, integration },
  setup() {
    // 声明myChart
    let myChart = null
    const state = reactive({
      isEnable: 1,
      isEnableArr: [
        { id: 0, value: '否' },
        { id: 1, value: '是' },
      ],
      byteVersions: ['0'],
      byteVersion: ['0'],
      platformNames: [],
      platformName: [],
      typeOfAmount: '0',
      typeOfAmounts: [
        { id: '0', value: '其他' },
        { id: '1', value: '小额' },
        { id: '2', value: '中额' },
        { id: '3', value: '大额' },
        { id: '4', value: '客服消息' },
        { id: '5', value: '图文消息' },
      ],
      spread: 2,
      day: '',
      // 小程序类别
      videoType: null,
      videoTypes: null,
      optypes: [
        { id: 1, value: '短剧微信小程序' },
        { id: 2, value: '短剧抖音小程序' },
        { id: 3, value: 'MiniShorts' },
      ],
      // 投放类型：
      optype: 1,
      pagesizes: [30, 50, 100, 250, 500, 1000],
      // 公众号每日回收详情
      WpRecoveryByDayData: [],
      // 公众号每日回收弹窗
      WpRecoveryByDayDeails: false,
      // 日月统计切换接口  0日统计 1月统计
      radio1: 0,
      // 页面接口选择
      type: 3,
      // 获取用户名称
      shujuyuanName: localStorage.getItem('userName'),
      // 获取数据员角色，不展示相关数据
      shujuyuan: localStorage.getItem('shujuyuan'),
      //获取角色权限编号
      rId: localStorage.getItem('rId'),
      //数据员这个有值才可以才看的到
      icon: localStorage.getItem('icon'),

      // 权限
      flag: localStorage.getItem('flag'),
      types: [
        { value: '小说', id: 1 },
        // { value: '测试', id: 2 },
        { value: '短剧', id: 3 },
        { value: 'iaa短剧', id: 4 },
        { value: '奇树', id: 5 },
        { value: '海外', id: 6 },
        { value: '快手', id: 7 },
      ],
      // 是否显示删除键
      addDeleteDialog: false,
      // 选择好的参数
      // recovery: '',
      platform: [],
      userName: [],
      bookName: [],
      wp: [],
      adPlatform: [],
      typeOfAmountss: [],
      partnerPlatform: [],
      groupName: [],
      // 排序的请求字段(降序)  0是充值 1是成本
      orderby: [
        {
          value: 0,
          label: '充值',
        },
        {
          value: 1,
          label: '成本',
        },
      ],
      // 排序的请求选中的值
      orderbyValue: 0,
      // 新增填写的数据
      addwp: '',
      adduserName: '',
      addadPlatform: '',
      addpartnerPlatform: '',
      addbookName: null,
      addnewUsers: null,
      addaccountId: null,
      accountId: [],
      addcost: null,
      // 首页的总消耗、充值、粉丝数的数据
      totalDatas: {},
      // 总付费成本
      getPayCostDate: 0,
      // 首页的总回收率
      TotalRecoverys: {
        amount: [0],
      },
      // 回收率
      // recoverys: [
      //   { value: '0-20%', id: 0 },
      //   { value: '20-40%', id: 0 },
      //   { value: '40-60%', id: 0 },
      //   { value: '60-80%', id: 0 },
      //   { value: '80-100%', id: 0 },
      //   { value: '>100%', id: 0 },
      // ],
      // 默认参数
      getRequestLists: [],
      // 快捷时间日
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '上个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '前三个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
        {
          text: '前半年',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            return [start, end]
          },
        },
        {
          text: '前一年',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            return [start, end]
          },
        },
      ],
      // 快捷时间月
      shortcutss: [
        {
          text: '本月',
          value: [new Date(), new Date()],
        },
        {
          text: '今年',
          value: () => {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)

            return [start, end]
          },
        },
        {
          text: '前六个月',
          value: () => {
            const end = new Date()
            const start = new Date()

            start.setMonth(start.getMonth() - 6)
            return [start, end]
          },
        },
      ],
      wps: [],
      // 日期
      date: [],
      thisdate: [],
      // 首充弹窗
      initialDeails: false,
      // 新增数据弹窗
      addDataShou: false,
      // 个人数据集合弹窗
      gatherDeailsDialog: false,
      // 表格数据
      tableData: [],
      // 条数
      pagesize: 30,
      // 当前页数
      currentPage: 1,
      // 总数
      total: 0,
      // 投放人下拉
      options: {},
      // 投放人、
      value1: '',
      // 首充列表
      firstDiy: [],
      // 当前成本
      rowcost: 0,
      // 集成数据
      IntegrationData: [],
      //海外要给集成组件的区别数据
      haiwaiData:{
        //判断是否启用 默认不启用
        start:false,
        //是否可以查看60天以为的 默认为不可以
        flag:0,
        //识别页面
        type:3,
      },
      //效验账户平台
      insetPlatformType: '巨量',
      //禁止修改书名控制
      noChangeOfBookName:true,
      //分时数据
       option : {
         tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'line' }
        },
        xAxis: {
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        // formatter: '{value} 时'
      },
      // type: 'category',
          name:'小时',
      data: ['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时', '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时']
      },
    yAxis: {
      axisLabel: {
        formatter: '{value} 元'
      },
    },
    series: [
      {
      data: [10, 0, 28, 43, 49],
      type: 'line',
      name:'今日',
    },
    {
      data: [5, 4, 3, 0, 10],
      type: 'line',
      name:'昨日'
    },
    {
      data: [15, 24, 33, 0, 60],
      type: 'line',
      name:'前日'
    }
  ]  
      },
      //销毁myChart
      destroyMyChart:()=>{
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
      },
      // 分时控制
      timeSharing:false,
      // 分时控制方法
      timeSharingFun:()=>{
        //清空之前的数据
        state.destroyMyChart()
        if(state.bookName.length==0&&state.userName.length==0&&state.typeOfAmountss.length==0&&state.platformNames.length==0&&state.videoTypes.length==0){
          return ElMessage({
            type: 'error',
            message: '请先选择书名、书城平台、投放人、充值模版、小程序类型中的其中一项',
          })

        }
        state.timeSharing=!state.timeSharing
      },
        
        

      //请求分时
        getTimeSharingOrderFun:()=>{
          let groupName = state.groupName

          if (state.groupName && state.groupName.length > 0) {
        groupName = [state.groupName[state.groupName.length - 1]]
      } else {
        groupName = []
      }
      const data = {
        starTime: state.date[0],
        endTime: state.date[1],
        bookName: state.bookName,
        platform: state.platform,
        wp: state.wp,
        userName: state.userName,
        adPlatform: state.adPlatform,
        partnerPlatform: [],
        groupName: groupName,
        type: state.type,
        typeOfAmount: state.typeOfAmountss,
        platformName: state.platformNames,
        byteVersion: [],
        accountId: state.accountId,
        isEnable: state.isEnable,
        flag: 0,
      }
      
      if (state.type == 3 || state.type == 4 || state.type == 5) {
        data.videoType = state.videoTypes
        state.typeOfAmount = state.typeOfAmountss
      }
        getTimeSharingOrder(data).then((res)=>{
         state.option.series= res.data

         state.initEcharts()
        })
      },

      // 页面首充
      firstAmount: 0,
      // 导出按钮方法
      excelOutFunon: () => {
        const excelQuery = {
          type: state.type,
          starTime: state.date[0],
          endTime: state.date[1],
          dateType: state.radio1 == 0 ? 'day' : 'month',
          userName: state.userName,
        }

        if (state.groupName == '') {
          excelQuery.groupName = []
        } else {
          excelQuery.groupName = [state.groupName[state.groupName.length - 1]]
        }

        axios
          .post('/excel/collectData', excelQuery, {
            responseType: 'blob',
          })
          .then((response) => {
            const content = response.data
            const blob = new Blob([content])
            const titleName =
              state.type == 1 ? '小说-' : state.type == 2 ? '测试-' : '短剧-'
            const fileName =
              titleName + state.date[0] + '-' + state.date[1] + '.xlsx'

            if ('download' in document.createElement('a')) {
              const link = document.createElement('a')

              link.download = fileName
              link.style.display = 'none'

              link.href = URL.createObjectURL(blob)
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
            } else {
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((error) => console.error(error))
      },
      // 首页导出
      exportFun: () => {
        let groupName = state.groupName

        if (state.groupName && state.groupName.length > 0) {
          groupName = [state.groupName[state.groupName.length - 1]]
        } else {
          groupName = []
        }

        const data = {
          starTime: state.date[0],
          endTime: state.date[1],
          bookName: state.bookName,
          platform: state.platform,
          wp: state.wp,
          userName: state.userName,
          adPlatform: state.adPlatform,
          partnerPlatform: [],
          groupName: groupName,
          type: state.type,
          typeOfAmount: state.typeOfAmountss,
          platformName: state.platformNames,
          byteVersion: [],
          accountId: state.accountId,
          isEnable: state.isEnable,
          flag: 0,
        }

        if (state.type == 3 || state.type == 4 || state.type == 5) {
          data.videoType = state.videoTypes
          state.typeOfAmount = state.typeOfAmountss
        }
        // fetch('http://dfrt.natapp4.cc/home/excelTableData', {
        // fetch('http://wxgzhapp.natapp1.cc/home/excelTableData', {
        fetch('https://wxappvue.df01.top/api/home/excelTableData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            authorize: 'Bearer' + localStorage.getItem('token'),
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            const titleName =
              state.type == 1
                ? '小说-'
                : state.type == 2
                ? '测试-'
                : state.type == 3
                ? '短剧-'
                : state.type == 4
                ? 'iaa短剧-'
                : state.type == 5
                ? '奇树'
                : state.type == 6
                ? '海外':'快手'
            const fileName =
              titleName + state.date[0] + '-' + state.date[1] + '.xlsx'

            link.download = fileName
            document.body.appendChild(link)
            link.click()
          })
          .catch((error) => console.error(error))
      },
      // 选择日期
      dateChange: () => {
        // tableDataFun()
        alldataFun()
      },
      // 清空新增内容的数据封装
      emptyFunAddData: () => {
        state.addwp = ''
        state.wps = ''
        state.adduserName = ''
        state.addadPlatform = ''
        state.addpartnerPlatform = ''
        state.addbookName = null
        state.addnewUsers = null
        state.addaccountId = null
        state.insetPlatformType = '巨量'
        state.addcost = null
        state.videoType = null
        state.typeOfAmount = '0'
        state.platformName = ''
        state.byteVersion = ['0']
      },
      // 新增数据按钮
      addDataFun: () => {
        //打开禁止修改书名
        state.noChangeOfBookName = false
        state.addDeleteDialog = false
        state.emptyFunAddData()
        const data = {
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }

        getFillParameter(data).then((res) => {
          state.options = res.data
          state.addDataShou = true
        })
      },
      // 取消新增
      addDataErr: () => {
        // 清空表单数据
        state.addDataShou = false
        state.emptyFunAddData()
      },
      // 确定新增
      addDataOk: () => {
        const data = {
          cost: state.addcost,
          bookName: state.addbookName,
          userName: state.adduserName,
          newUsers: state.addnewUsers,
          accountId: state.addaccountId,
          adPlatform: state.addadPlatform,
          partnerPlatform: state.addpartnerPlatform,
          insetPlatformType: state.insetPlatformType,
          type: state.type,
          typeOfAmount: state.typeOfAmount,
          platformName: state.platformName,
          byteVersion: '0',
        }

        if (state.videoType != '') {
          data.videoType = state.videoType
        }
        if (
          (state.videoType == 1 && state.spread != 1) ||
          (state.type != 3 && state.type != 4 && state.type != 5&& state.type != 6&& state.type != 7)
        ) {
          data.day = state.addwp.split('*')[1]
          data.wp = state.addwp.split('*')[0]
        } else {
          data.day = state.day
          data.wp = state.wps
        }

        // if (state.type == 3 && state.optype == 1) {
        //   data.videoType = 3
        // }
        for (const key in data) {
          if (data[key] === '') {
            ElMessage.error('文本内容不能为空')
            return
          }
        }
        updateContext(data).then((res) => {
          ElMessage({
            type: 'success',
            message: res.msg,
            // 提交表单
          })
          state.addDataShou = false
          // tableDataFun()
          alldataFun()
          state.emptyFunAddData()
        })
      },

      //补iaa的数据
      suppDataFun: (scope) => {
        const datas = {
          accountId: scope.row.accountId,
          day: scope.row.day,
          wp: scope.row.wp,
          platform: scope.row.platform,
          adPlatform:scope.row.adPlatform,
          type:state.type,
        }
        suppData(datas).then((res) => {
          if(res.code==200){
            ElMessage({
            type: 'success',
            message: '数据补充成功，请点击搜索按钮刷新页面数据',
          })
          }

        })
      },

      // 集成详情弹窗
      gatherDeailsDialogFun: () => {
        if (state.shujuyuan == 'shujuyuan') {
          return ElMessage({
            type: 'error',
            message: '暂未开放',
          })
        } else {
          let groupName = state.groupName

          if (state.groupName && state.groupName.length > 0) {
            groupName = [state.groupName[state.groupName.length - 1]]
          }
          if (null == groupName) {
            groupName = []
          }
          const data = {
            type: state.type,
            starTime: state.date[0],
            endTime: state.date[1],
            date: state.radio1 == 0 ? 'day' : 'month',
            groupName: groupName,
            userName: state.userName,
            bookName: state.bookName,
            platformName: state.platformName,
            platform:state.platform,
          }
          //每次先初始化
            state.haiwaiData.start=false
            state.haiwaiData.flag=0
            state.haiwaiData.type=3
            // 海外页面
           if(state.type==6){
            state.haiwaiData.start=true
            state.haiwaiData.type=6
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
              state.haiwaiData.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
              state.haiwaiData.flag=0
            }
          }

          getIntegrationData(data).then((res) => {
            state.IntegrationData = res.data
            state.gatherDeailsDialog = true
          })
        }
      },

      // 查询内容是否填写
      getContextFun: () => {
        const data = {
          day: state.addwp.split('*')[1],
          wp: state.addwp.split('*')[0],
        }

        getContext(data).then((res) => {
          state.addbookName = res.data.bookName
          state.addnewUsers = res.data.newUsers
          state.addaccountId = res.data.accountId
          state.addcost = res.data.cost
          state.adduserName = res.data.userName
          state.addadPlatform = res.data.adPlatform
          state.addpartnerPlatform = res.data.partnerPlatform
        })
      },
      // 删除该条数据
      addDelete: () => {
        const data = {
          day: state.addwp.split('*')[1],
          wp: state.addwp.split('*')[0],
          type: state.type,
        }

        deleteContext(data).then((res) => {
          ElMessage({
            type: 'success',
            message: '删除成功',
            // 提交表单
          })
          state.addDataShou = false
          state.emptyFunAddData()
          // tableDataFun()
          alldataFun()
        })
      },
      // 首充弹窗
      initialDeailsFun: () => {
        if (state.shujuyuan == 'shujuyuan'&&state.shujuyuanName!='xieqianhui2') {
          ElMessage({
            type: 'error',
            message: '暂未开放',
            // 提交表单
          })
        } else {
          const data = dataFun()

          // 海外页面
          if(state.type==6){
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
            }
          }

          if (state.radio1 == 0) {
            TotalRecovery(data).then((res) => {
              state.TotalRecoverys = res.data.recycle
              state.firstAmount = res.data.firstAmount

              state.firstDiy = []
              state.initialDeails = true


            })
          } else {
            TotalRecoveryByMonth(data).then((res) => {
              state.TotalRecoverys = res.data.recycle
              state.firstAmount = res.data.firstAmount

              state.firstDiy = []
              state.initialDeails = true

            })
          }
        }
        // 首页的总回收率
      },
      // 修改新增数据
      addDetailsFun: (row, column) => {
        //禁修改书名
        state.noChangeOfBookName = true
        if(state.rId==16){
          state.noChangeOfBookName = false
        }
        if (state.shujuyuanName.indexOf('观察员') != -1||state.shujuyuanName=='测试') {
          return ElMessage({
            type: 'error',
            message: '暂无权限',
          })
        }
        if (column.label == '投放人') {
          const data = {
            starTime: state.date[0],
            endTime: state.date[1],
            type: state.type,
          }

          getFillParameter(data).then((res) => {
            if (row.videoType != '') {
              // state.type = 3
              state.optype = 2
              state.videoType = row.videoType
              state.day = row.day
              state.wps = row.wp
            }

            state.options = res.data
            state.addDataShou = true
            state.addwp = row.wp + '*' + row.day
            state.adduserName = row.userName
            state.addadPlatform = row.adPlatform
            state.addpartnerPlatform = row.partnerPlatform
            state.addbookName = row.bookName
            state.addnewUsers = row.newFans
            state.addaccountId = row.accountId2
            state.addcost = row.cost
            state.typeOfAmount = row.typeOfAmount
            state.platformName = row.platformName
            state.addDeleteDialog = true
            state.byteVersion = '0'
          })
        } else if (column.label == '回收率' && state.radio1 == 0) {
          state.rowcost = row.cost
          const data = {
            starTime: state.date[0],
            endTime: state.date[1],
            day: row.day,
            wp: row.wp,
          }
          // 海外页面
          if(state.type==6){
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
            }
          }

          WpRecoveryByDay(data).then((res) => {
            state.WpRecoveryByDayData = res.data
            state.WpRecoveryByDayDeails = true
          })
        }
      },
          // 图表
     initEcharts:()=> {
      myChart = echarts.init(document.getElementById('timeSharingCharts'))
    // myChart.setOption(option)
      myChart.setOption(state.option)
    },
      
      // 选择小说  短剧 测试 iaa短剧接口
      typehange: () => {
        alldataFun()
      },
      // 日月统计
      typehanges: () => {
        setCurrentDate()
        alldataFun()
      },
      // 分页开始
      // 页数 发生改变
      handleCurrentChange: (e) => {
        state.currentPage = e
        tableDataFun()
      },
      // 个数 发生改变
      handleSizeChange: (e) => {
        state.pagesize = e
        tableDataFun()
      },
      // 分页结束
    })
    // 默认参数
    const dataFun = () => {
      let groupName = state.groupName

      if (state.groupName && state.groupName.length > 0) {
        groupName = [state.groupName[state.groupName.length - 1]]
      }
      if (null == groupName) {
        groupName = []
      }
      const data = {
        starTime: state.date[0],
        endTime: state.date[1],
        bookName: state.bookName,
        platform: state.platform,
        wp: state.wp,
        userName: state.userName,
        // groupName: state.groupName,
        adPlatform: state.adPlatform,
        partnerPlatform: state.partnerPlatform,
        groupName: groupName,
        size: state.pagesize,
        currentPage: state.currentPage,
        type: state.type,
        typeOfAmount: state.typeOfAmountss,
        platformName: state.platformNames,
        byteVersion: [],
        accountId: state.accountId,
        isEnable: state.isEnable,
      }

      if (state.type == 3 || state.type == 4 || state.type == 5) {
        data.videoType = state.videoTypes
        state.typeOfAmount = state.typeOfAmountss
      }
      return data
    }
    // 默认日期
    const setCurrentDate = () => {
      const s = new Date().getTime()
      const date = new Date(s)
      // 年
      const year = date.getFullYear()
      // 月
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      // 日
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      // // 时
      // const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      // // 分
      // const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // // 秒
      // const ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // + ' ' + hh + ':' + mm + ':' + ss

      if (state.radio1 == 0) {
        state.thisdate = [
          year + '-' + month + '-' + day,
          year + '-' + month + '-' + day,
        ]
        state.date = [
          year + '-' + month + '-' + day,
          year + '-' + month + '-' + day,
        ]
      } else if (state.radio1 == 1) {
        state.date = [year + '-' + month, year + '-' + month]
      }
    }

    setCurrentDate()


    // 首页下方默认表格
    const tableDataFun = () => {
      const data = dataFun()
      // 清空默认首充
      state.firstAmount = 0
      // 判断是否是月统计  0是日 1是月
      if (state.radio1 == 0) {
        data.flag = state.orderbyValue
        tableData(data).then((res) => {
          state.tableData = res.data.data
          state.currentPage = res.data.currentPage
          state.total = res.data.totalSize
        })
        // //首页导出 未上线！！(勿删)
        // homePageDataExcel(data).then((res) => {
        //   console.log('导出==>', res)
        // })
      } else if (state.radio1 == 1) {
        data.flag = state.orderbyValue
        tableDataByMonth(data).then((res) => {
          state.tableData = res.data.data
          state.currentPage = res.data.currentPage
          state.total = res.data.totalSize
        })
      }
    }
    // 获取首页数据 集合
    const alldataFun = () => {
      const data = dataFun()
      // 首页下方默认表格
      tableDataFun()

          // 海外页面要限制、其他页面不限制
          if(state.type==6){
            if(state.rId==16||state.shujuyuanName == 'xieqianhui2'|| state.shujuyuanName == '董翔2'){
              //看所有
              data.flag=1
            }else{
              //只能才看60天之内的
              data.flag=0
            }
          }else{
            //不限制默认看全部
            data.flag=1
          }      

      // 判断是否是月统计  0是日 1是月
      if (state.radio1 == 0) {
        // 首页的总消耗、充值、粉丝数的数据
        totalData(data).then((res) => {
          state.totalDatas = res.data
        })

        // 付费成本
        // getPayCost(data).then((res) => {
        //   state.getPayCostDate = res.data
        // })
        // 请求参数
        getRequestList({
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }).then((res) => {
          state.getRequestLists = res.data
        })
      } else if (state.radio1 == 1) {
        // 月统计的总消耗、充值、粉丝数的数据
        totalDataByMonth(data).then((res) => {
          state.totalDatas = res.data
        })
        // 月统计的总回收率

        // 默认请求参数
        monthgetRequestList({
          starTime: state.date[0],
          endTime: state.date[1],
          type: state.type,
        }).then((res) => {
          state.getRequestLists = res.data
        })
      }
    }
    //限制观察员
    const xzgcyFun=()=>{
     if(state.shujuyuan=='shujuyuan'&&(state.icon=='undefined'||state.icon=='null')) {
      state.types=[
      { value: '小说', id: 1 },
        { value: '短剧', id: 3 },
        { value: 'iaa短剧', id: 4 },
        { value: '奇树', id: 5 },
        { value: '快手', id: 7 },
      ]
     }
    }
    xzgcyFun()
    // 在组件卸载之前 注销myChart  避免切换页面无法渲染
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
    alldataFun()

    //实验请求之后没参数变为string
    // test=()=>{

    // }

    return {
      ...toRefs(state),
      tableDataFun,
      alldataFun,
      xzgcyFun
    }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.indexdata-top {
  margin-top: 10px;
}
/deep/ .el-input--suffix .el-input__inner {
  height: 40px !important;
}
.map {
  width: 600px;
  height: 410px;
}
.datashou {
  margin-top: 20px;
  margin-bottom: 20px;
}
.data-car {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
  > p {
    margin-bottom: 5px;
  }
  span {
    color: #f56c6c;
    font-size: 18px;
  }
}

.data-carfor {
  margin-top: 10px;
  padding-bottom: 20px;
  span {
    // display: block;
    display: inline-block;
    margin-right: 30px;
    width: 190px;
    margin-top: 10px;
    height: 80px;
    // cursor: pointer;
    p {
      font-size: 18px;
      text-align: center;
    }
  }
}

.index-top-right {
  margin-left: 20px;
  .map {
    width: 700px;
  }
}
.indexdata-bot {
  // margin-bottom: 40px;
  max-width: 1600px;
}
.indexdata-bot-select {
  margin-left: 8px;
  width: 160px;
}
.indexdata-bot-top {
  margin-bottom: 10px;
  justify-content: space-between;
}
.initialDeails {
  flex-wrap: wrap;
}
.initialDeailsitem {
  width: 180px;
  border: thin solid rgba(0, 0, 0, 0.22);
  // height: 140px;
  box-sizing: border-box;
  padding: 6px 0;
  padding-left: 15px;
  p {
    margin-top: 6px;
    font-size: 15px;
  }
}

.searchbtn {
  margin-left: 8px;
}
.addDataShouSelect {
  width: 400px !important;
  margin-top: 20px;
}
.addDataShouSelect-name {
  width: 150px;
  text-align: right;
}
.depart-btn {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  > div {
    margin-left: 20px;
  }
}

.bookNameTitle{
  font-size: 18px;
  font-weight: bold;
  color:rgb(240, 106, 106);
  // justify-content:center;
}
</style>
